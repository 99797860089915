.loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);

  &-title {
    // color: #ffad4c;
    color: white;
    font-size: 2rem;
    margin: 20px 0;
  }
}
