$margin: 2%;
$orange: #ffad4c;
$text-gray-color: rgba(75, 75, 75, 1);
$BG-gray: rgba(248, 248, 248, 0.8);

.navbar-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 10px 0 10px 0;
  background-color: rgba(39, 36, 37, 1);

  .order-btn {
    margin-right: $margin;
  }
}
.logo-container {
  z-index: 10;
  margin-left: $margin;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 10px;
  background-color: rgba(52, 56, 61, 1);
  border: solid 2px $text-gray-color;
  border-radius: 5px;
}
.logo-title-2,
.logo-title-1 {
  font-size: 2rem;
  color: white;
  margin: 0 5px;
}
.logo-title-1 {
  color: $orange;
}

.links-container {
  display: flex;
  border: solid 1px red;
  padding: 0 5px;
}
.nav-link {
  color: white;
  padding: 0 20px;
  font-size: 1.5rem;
}
.nav-link.active {
  color: $orange;
}
.hamburger {
  display: none;
  z-index: 10;
  background-color: hsl(213, 8%, 22%);
  border-radius: 10px;
  border: solid 2px $text-gray-color;
  margin-right: $margin;
}

@media screen and (max-width: 1000px) {
  .nav-links-container {
    display: none;
  }
  .order-btn {
    display: none;
  }
  .hamburger {
    display: flex;
  }
  .logo-title-2,
  .logo-title-1 {
    font-size: 1.5rem !important;
    // color: white;
    // margin: 0 5px;
  }
}
