$orange: rgba(255, 173, 76, 1);
$margin: 2%;

.footer-container {
  width: 100%;
  // height: 100px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0 10px 0;
  background-color: rgba(39, 36, 37, 1);
}

.footer-logo-container {
  margin-left: $margin;

  .footer-logo {
    display: flex;
    margin-bottom: 15px;

    &-title-1,
    &-title-2 {
      font-size: 2rem;
    }
    &-title-1 {
      color: $orange;
    }
    &-title-2 {
      color: white;
      margin-left: 5px;
    }
  }
  .footer-left-info {
    color: white;
    font-size: 1.2rem;
  }
}
.footer-center-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .contacts-title {
    color: $orange;
    font-size: 1.5rem;
  }
  .contacts-container {
    display: flex;

    .contacts-block {
      margin: 10px;

      .contacts-item {
        display: flex;
        align-items: center;
        margin-bottom: 5px;

        .contacts-text,
        .contacts-icon {
          margin-left: 10px;
          color: white;
          text-align: center;
          font-size: 1.1rem;
        }
        .contacts-icon {
          display: flex;
          align-items: center;
          justify-content: center;
          color: $orange;
        }
      }
    }
  }
}

.footer-right-container {
  margin-right: $margin;
}

@media screen and (max-width: 1000px) {
  .footer-logo-container {
    .footer-logo {
      margin-bottom: 10px !important;
      &-title-1,
      &-title-2 {
        font-size: 1.5rem !important;
      }
    }
    .footer-left-info {
      color: white;
      font-size: 1rem !important;
    }
  }
  .footer-center-container {
    display: none;
  }
}
