$orange: rgba(255, 173, 76, 1);

.order-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  padding: 5px 5px;
  color: white;
  font-size: 1.5rem;
  text-align: center;
  background-color: $orange;
  border-color: rgba(52, 56, 61, 1);
  border-radius: 8px;
  cursor: pointer;
}

// @media screen and (max-width: 1080px) {
//   .order-btn {
//     display: none;
//   }
// }
