$orange: rgba(255, 173, 76, 1);
$BG-gray: rgba(248, 248, 248, 0.8);
$text-gray-color: rgba(75, 75, 75, 1);
$text-black-color: rgba(58, 58, 58, 1);

.sidebarmenu-container.active {
  left: 0;
  transition: 350ms;
}
.sidebarmenu-container {
  z-index: 5;
  position: absolute;
  top: 0;
  left: -500%;
  // height: 100%;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  transition: 850ms;

  display: flex;
  justify-content: center;
  align-items: center;

  .sidebar-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    position: relative;

    .sidebar-links-container {
      margin-top: 80%;
      display: flex;
      flex-direction: column;

      .sidebar-nav-link {
        display: flex;
        align-items: center;
        .nav-link-icon {
          display: flex;
          align-items: center;
          justify-content: center;
          color: white;
          font-size: 1.8rem;
        }

        .nav-link-title {
          color: white;
          font-size: 2rem;
          margin: 10px;
          text-align: center;
        }
      }
      .sidebar-nav-link.active {
        color: $orange;
        .nav-link-title {
          color: $orange;
        }
        .nav-link-icon {
          color: $orange;
        }
      }
    }
    .sidebar-footer-container {
      position: relative;
      bottom: 0;
      margin-bottom: 25%;
      .social-links-container {
        display: flex;
        align-items: center;
        justify-content: center;
        .icon-social-link {
          color: white;
          // color: $orange;
          font-size: 2rem;
          margin: 10px 5px;
        }
      }
    }
  }
}
